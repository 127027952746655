import React from 'react'
import { graphql } from "gatsby"
import showdown from "showdown"
import replaceAll from "../utils/replaceAll"
import Seo from "../components/Seo"
import Hero from '../components/Hero'
import { StaticImage } from "gatsby-plugin-image"
import SignUpSection from '../components/SignUpSection'

export default function Email({data}) {
  const Narrative = ({ title, content }) => {
    // = = = = = = = = = = = = =
    // Utils
  
    // Convert MarkDown to HTML
    const converter = new showdown.Converter();
    const htmlTitle = converter.makeHtml(title);
    const htmlContent = converter.makeHtml(content);
  
    // Add icon to each link of the article
    const replacedHtmlContent = replaceAll(htmlContent, '</a>', '<span class="link-arrow">›</span></a>');
  
    return (
      <div className="narrative-basic">
        <div className="container">
          <div className="row">
  
            <div className="col-12">
              <div className="narrative__headline" dangerouslySetInnerHTML={{__html: htmlTitle }} />
            </div>
  
          </div>
  
          <div className="row">
  
            <div className="col-12">
  
              <div className="narrative__content" dangerouslySetInnerHTML={{__html: replacedHtmlContent }} />
  
            </div>
  
          </div>
          {/* <div className="narrative__picture-wrapper--bg">
            <StaticImage 
              className=""
              src="../../static/media/narrative-1-bg.png" 
              alt="" 
              placeholder="blurred"
            />
          </div> */}
        </div>
      </div>
    )
  }
  const pageData = data?.markdownRemark?.frontmatter;
  const emailSections = data?.markdownRemark?.frontmatter.page_sections;
  return (
    <>
     <Seo title = { pageData.page_title } />
    <Hero 
        headline = { pageData?.hero_subhead }
        intro = { pageData?.hero_intro }
        image = { pageData?.hero_background_image }
      >
        <StaticImage 
          className="hero__picture"
          src="../../static/media/about-hero.jpg" 
          alt="" 
          loading="eager"
        />
      </Hero>
    <div className="page-content">
        {
          emailSections.map(item => 
            <Narrative title={item.section_headline} content={item.section_content} />
          )
        }
    </div>

      <SignUpSection />
    </>
    
    
  )
}

export const query = graphql`
  query EmailPageQuery {
    markdownRemark(frontmatter: {page_title: {eq: "Email"}}) {
      frontmatter {
        page_title
        hero_intro
        hero_subhead
        hero_intro
        page_sections{
          section_headline
          section_content
        }
      }
    }
  }
`;
